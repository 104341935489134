<template>
  <div id="module"></div>
  <main>
    <router-view />
  </main>
</template>

<style lang="scss">
/*-- Global Styles --*/
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

body {
  margin: 0;
  background-color: #151619;
  background-image: url('./assets/img/table_salon.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
