<template>
  <div class="slideshow">
    <transition-group name="fade" tag="div" class="slides">
      <div v-for="item in filteredSlides" :key="item.src" class="slide">
        <img
          :src="item.src"
          v-if="item.type == 'img'"
          loading="lazy"
          alt="image"
        />
        <video
          :src="item.src"
          v-else-if="item.type == 'video'"
          autoplay
          loop
          muted
        ></video>
        <!-- <iframe :src="item.src" v-if="item.type == 'web'" frameborder="0"></iframe>
                    <div class="slideInfo" v-if="'text' in item">
                        <div class="slideHeading">{{item.text}}</div>
                        <div v-if="'url' in item"><button @click="openLink(item.url)">More Info</button></div>
                    </div> -->
      </div>
    </transition-group>
    <!-- <div el="btn" @click="next">Next</div>
            <div el="btn" @click="prev">Prev</div> -->
  </div>
</template>

<script>
export default {
  name: "Slideshow",
  //props: ['data'],
  data() {
    return {
      speed: 2.5, //in seconds
      autoplay: true,
      action: null,
      currentSlide: 0,
      slides: [
        // {
        //     src: require("@/assets/img/club_house.jpg"),
        //     type: 'img',
        //     // speed: 5,
        // },
        {
          src: "/assets/img/hazel photo 9.jpg",
          type: "img",
          //speed: 44,
        },
        {
          src: "/assets/img/hazel photo 10.jpg",
          type: "img",
          //speed: 44,
        },
        {
          src: "/assets/img/hazel photo 4.jpg",
          type: "img",
          //speed: 44,
        },
        {
          src: "/assets/img/equipment.webp",
          type: "img",
          //speed: 44,
        },
        {
          src: "/assets/img/hazel photo 6.jpg",
          type: "img",
          //speed: 44,
        },
        // {
        //     src: '/assets/img/hair.jpg',
        //     type: 'img',
        // },
        {
          src: "/assets/img/table_salon.webp",
          type: "img",
        },
        // {
        //     src: '/assets/img/background3.jpg',
        //     type: 'img',
        // },
        // {
        //     src: '/assets/img/background4.jpg',
        //     type: 'img',
        // },
      ],
    };
  },
  computed: {
    filteredSlides() {
      var newArr = [];
      this.slides.forEach((item, index) => {
        if (index <= this.currentSlide && index >= this.currentSlide - 1) {
          newArr.push(item);
        }
      });
      return newArr;
    },
  },
  methods: {
    next() {
      var x = this,
        speed = x.speed * 1000;
      x.currentSlide = (x.currentSlide + 1) % x.slides.length;
      if ("speed" in this.slides[x.currentSlide]) {
        speed = this.slides[x.currentSlide].speed * 1000;
      }
      this.clearAction();
      this.action = setTimeout(function() {
        x.next();
      }, speed);
    },
    prev() {
      var x = this,
        speed = x.speed * 1000;
      x.currentSlide =
        x.currentSlide - 1 < 0
          ? x.slides.length - 1
          : (x.currentSlide - 1) % x.slides.length;
      if ("speed" in this.slides[x.currentSlide]) {
        speed = this.slides[x.currentSlide].speed * 1000;
      }
      this.clearAction();
      this.action = setTimeout(function() {
        x.prev();
      }, speed);
    },
    clearAction() {
      if (this.action != null) {
        clearTimeout(this.action);
      }
    },
  },
  mounted() {
    var x = this;
    if (x.autoplay == true && x.slides.length > 1) {
      setTimeout(function() {
        x.next();
      }, x.speed * 1000);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.slideshow {
  position: absolute;
  opacity: 1;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
}
/* .slideshow img{
    width:calc(100% - 80px);
    height:calc(100% - 80px);
    margin:40px;
    top:0;
    left:0;
    object-fit: cover;
    object-position: center;
    overflow:hidden;
    border-radius:8px;
    position: absolute;
} */

.slideshow .slides {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* top:40px;
    left:40px; */
  opacity: 0;
  animation: fade-in forwards 2s;
  border-radius: 8px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slideshow .slide,
.slideshow .slide > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 20%;
  position: absolute;
  top: 0;
  left: 0;
}

/*
.slide>* {
    animation: fade-in forwards 1s;
}
*/

.slideshow .slideInfo {
  background-color: hsla(0, 0%, 0%, 0.3);
  padding: 40px;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 1);
}

/*
.slideshow .slideInfo>* {
    max-width: 900px;
    width: 100%;
}
*/

.slideshow .slideHeading {
  font-size: 4vmax;
  line-height: 1.3em;
}

@media (max-width: 600px) {
  .slideshow .slides {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 0px;
  }
}
</style>
