// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/storage";
// import "firebase/performance";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyB9-mOpOROKS_VIOYaiz1iGt4eqTAWTDwk",
  authDomain: "kbl-login.firebaseapp.com",
  projectId: "kbl-login",
  storageBucket: "kbl-login.appspot.com",
  messagingSenderId: "550005795777",
  appId: "1:550005795777:web:9d6b58c7f3a1faa7179d40",
  measurementId: "G-M884L71RRY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);
const db = getFirestore(app);

export { db };

// utils
// export const db = firebase.firestore();
// export const auth = firebase.auth();
// export const storage = firebase.storage();
