<template>
    <p el>We would like to inform you about restrictions applied at our studio.</p>
    <p el>We are taking the necessary precautions to create a safe and controlled environment. If you are experiencing symptoms or have traveled overseas within 2 weeks or less before your appointment please inform your stylist and they will gladly reschedule to a better time.</p>
    <p el>As stylists, we wear a mask to protect you and us. For now, our guests have to wear a mask to their appointment. No mask, no service-  there is no exception! </p>
    <p el>Upon entering the salon your temperature will be taken and you will be asked to sanitize your hands. </p>
    <p el>We are a Studio/Salon with limited space, so be mindful to not bring anyone with you during your service. </p>
    <p el>Please do not come early for your appointment.</p>
    <p el>We will need additional time for cleaning and we cannot have people gathering in or in front of the building.</p>
    <p el>Please DM or Text your personal stylist while you wait in your car until they are ready for you. We will greet you at the front door as it is locked due to COVID-19 regulations. </p>
    <p el>Unfortunately, we will not be able to service beverages at the time.</p>
    <p el>Please bring a minimum amount of items with you.</p>
    <p el>Thank you for understanding and we are so excited to see you at Kulture Beauty Lounge!</p>
</template>
<script>
export default {
  name: 'CovidPolicy',
}
</script>