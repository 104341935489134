<template>
  <div
    el="list-column"
    style="--gap:8px;justify-content:space-evenly;align-items:center;"
    class="nav-container"
  >
    <div v-if="window.mobileWidth">
      <div el="list" class="mobile-nav">
        <router-link to="/about" class="nav-link">about</router-link>
        <router-link to="/talent" class="nav-link">talent</router-link>
        <router-link to="/booking" class="nav-link">booking</router-link>
        <router-link to="/info" class="nav-link">info</router-link>
      </div>
      <!-- <span class="material-icons mobile-menu-icon" style="color:#fff;z-index:10;" @click="toggleMobileNav">menu</span> -->
      <span
        class="mobile-menu-icon"
        style="color:#fff;z-index:10;"
        @click="toggleMobileNav"
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="menu " id="menu_">
            <path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z" />
            <path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" />
            <path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z" />
          </g>
        </svg>
      </span>
    </div>
    <router-link to="/about" class="nav-link" v-if="!window.mobileWidth"
      >about</router-link
    >
    <router-link to="/talent" class="nav-link" v-if="!window.mobileWidth"
      >talent</router-link
    >
    <router-link to="/" class="none">
      <!-- <img src="../assets/img/logo/logo.png" class="logo" alt="logo"> -->

      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 189.33 164.39"
        class="logo"
      >
        <path
          class="letters"
          d="M29.26,135.89a17.68,17.68,0,0,0-4.66-3.33,15.23,15.23,0,0,0-6.17-1.39c-8.25,0-14.36,6.64-14.36,14.9a14.3,14.3,0,0,0,14.42,14.57A14.75,14.75,0,0,0,25.13,159a16.29,16.29,0,0,0,4.13-3v4.93a18.61,18.61,0,0,1-10.77,3.48A18.07,18.07,0,0,1,0,146c0-10.66,8-18.59,18.7-18.59A16.89,16.89,0,0,1,29.26,131Z"
        />
        <path
          class="letters"
          d="M42.28,128c2.41,0,6,.16,8.68,2a9.33,9.33,0,0,1,3.91,7.93c0,5.47-3.59,9.49-9,9.86l11.36,16H52.41L41.53,148.1h-1v15.65h-4V128Zm-1.77,16.56H43c4.93,0,7.88-2.36,7.88-6.54a5.56,5.56,0,0,0-2.84-5.25,10.22,10.22,0,0,0-5.15-1H40.51Z"
        />
        <path
          class="letters"
          d="M82,131.76H67.41v10.61H81.56v3.75H67.41V160H82v3.75H63.45V128H82Z"
        />
        <path
          class="letters"
          d="M109,153.68H93.78l-4.29,10.07H85.1l16.45-37.46,16,37.46h-4.39Zm-1.56-3.76-6-14.3-6.11,14.3Z"
        />
        <path class="letters" d="M129,131.76v32h-4v-32h-8.68V128h21.33v3.75Z" />
        <path
          class="letters"
          d="M161.57,131.76H147v10.61h14.15v3.75H147V160h14.58v3.75H143V128h18.54Z"
        />
        <path
          class="letters"
          d="M184.83,135.08a8.81,8.81,0,0,0-2.09-2.68,6.5,6.5,0,0,0-4-1.23c-3.54,0-6,2.2-6,5.52a3.74,3.74,0,0,0,1.34,3.11,11.68,11.68,0,0,0,3.75,2.25l3.43,1.5a17.33,17.33,0,0,1,5.52,3.37,8.64,8.64,0,0,1,2.57,6.49c0,6.59-4.88,11-11.31,11a10.66,10.66,0,0,1-7.77-2.89,12.9,12.9,0,0,1-3.32-6.32l3.86-1.07a8.36,8.36,0,0,0,2.14,4.55,7.32,7.32,0,0,0,12.33-5.09,5.35,5.35,0,0,0-1.77-4.18,15.66,15.66,0,0,0-4.29-2.47l-3.22-1.5a15.87,15.87,0,0,1-4.77-2.94,7.44,7.44,0,0,1-2.46-5.84c0-5.52,4.23-9.22,10-9.22A9.64,9.64,0,0,1,184.5,129a10.84,10.84,0,0,1,3.49,4Z"
        />
        <polygon
          class="c"
          points="189.33 17.23 189.33 0 121.88 0 121.88 116.92 189.33 116.92 189.33 99.69 139.11 99.69 139.11 17.23 189.33 17.23"
        />
        <polygon
          class="ht"
          points="93.69 0 93.69 35.75 17.37 35.75 17.37 0 0.14 0 0.14 52.98 0.14 52.98 0.14 52.98 110.92 52.98 110.92 52.98 110.92 0 93.69 0"
        />
        <polygon
          class="hb"
          points="0.14 63.94 0.14 63.94 0.14 116.92 17.37 116.92 17.37 81.17 93.69 81.17 93.69 116.92 110.92 116.92 110.92 63.94 0.14 63.94"
        />
      </svg>
    </router-link>
    <router-link to="/booking" class="nav-link" v-if="!window.mobileWidth"
      >services</router-link
    >
    <router-link to="/info" class="nav-link" v-if="!window.mobileWidth"
      >info</router-link
    >
    <!-- <div el="list-column" style="fill:white;">
          <a href="http://www.facebook.com/balayagebyali" target="_blank" rel="noopener noreferrer" style="fill:white;">
              <svg height="18" width="18" viewBox="0 0 46 46" data-reactid="64"><path d="m28.1 10.4c.3-.4 1.2-.6 2.4-.6h4.3c.6 0 1-.4 1-1v-7.3c0-.5-.4-.9-.9-1-1.5-.2-3.7-.3-6.4-.3-3.4 0-6.2 1-8.3 3.1s-3.1 5-3.1 8.6v4h-6c-.6 0-1 .4-1 1v8.1c0 .6.4 1 1 1h6v19c0 .6.4 1 1 1h8.4c.6 0 1-.4 1-1v-19h6c.5 0 .9-.4 1-.9l1.1-8.1c0-.3 0-.6-.2-.8s-.5-.3-.8-.3h-7v-3.2c-.1-1.1.1-1.9.5-2.3zm-1.6 7.5h6.9l-.8 6.1h-6.1c-.6 0-1 .4-1 1v19h-6.4v-19c0-.6-.4-1-1-1h-6v-6.1h6c.6 0 1-.4 1-1v-5c0-3.1.8-5.5 2.6-7.2 1.7-1.7 4-2.5 6.9-2.5 2.1 0 3.9.1 5.2.2v5.4h-3.3c-1.9 0-3.2.4-3.9 1.3-.7.8-1.1 2.1-1.1 3.6v4.2c0 .5.5 1 1 1z" data-reactid="65"></path></svg>
          </a>
          <a href="https://www.instagram.com/balayagebyali" target="_blank" rel="noopener noreferrer" style="fill:white;">
              <svg height="18" width="18" viewBox="0 0 46 46" data-reactid="67"><path d="m23 12.7c-5.7 0-10.3 4.6-10.3 10.3s4.6 10.3 10.3 10.3 10.3-4.6 10.3-10.3-4.6-10.3-10.3-10.3zm0 18.6c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3zm12.2-23.2c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.5-2.6c.1-1.5-1-2.6-2.5-2.6z" data-reactid="68"></path><path d="m32.4.7h-18.8c-7.1 0-13 5.8-13 13v18.8c0 7.1 5.8 13 13 13h18.8c7.1 0 13-5.8 13-13v-18.9c0-7.1-5.9-12.9-13-12.9zm11 31.7c0 6-4.9 11-11 11h-18.8c-6 0-11-4.9-11-11v-18.8c0-6 4.9-11 11-11h18.8c6 0 11 4.9 11 11z" data-reactid="69"></path></svg>
          </a>
      </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import {auth,db} from '../firebase'
import gsap from "gsap";

export default {
  name: "Nav",
  components: {
    // HelloWorld
  },
  data() {
    return {
      animateLogo: true,
      window: {
        width: 800,
        mobileWidth: true,
      },
      nav: {
        show: false,
      },
    };
  },
  methods: {
    toggleMobileNav() {
      var x = this;
      this.nav.show = !this.nav.show;
      if (x.nav.show) {
        gsap.to(".mobile-nav", {
          width: "100%",
          opacity: 1,
          duration: 0.5,
        });
      } else {
        gsap.to(".mobile-nav", {
          width: "0%",
          opacity: 0,
          duration: 0.5,
        });
      }
    },
  },
  computed: {},
  mounted() {
    var x = this;
    x.window.width = window.innerWidth;
    if (x.window.width <= 800) {
      x.window.mobileWidth = true;
    } else {
      x.window.mobileWidth = false;
    }
    window.addEventListener("resize", () => {
      x.window.width = window.innerWidth;
      if (x.window.width <= 800) {
        x.window.mobileWidth = true;
      } else {
        x.window.mobileWidth = false;
      }
    });

    if (x.animateLogo && this.$router.currentRoute._value.fullPath == "/") {
      gsap
        .timeline()
        .set(".logo", {
          scale: 3,
          y: 500,
        })
        .from(".ht", {
          duration: 0.5,
          y: "-150%",
        })
        .from(
          ".c",
          {
            duration: 0.5,
            x: "100%",
          },
          "-=0.2"
        )
        .from(
          ".hb",
          {
            duration: 0.5,
            y: "200%",
          },
          "-=0.2"
        )
        .from(
          ".letters",
          {
            duration: 0.5,
            stagger: 0.1,
            opacity: 0,
            y: "100%",
          },
          "-=0.2"
        )
        .to(".logo", {
          scale: 1,
          y: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .from(
          "#main-center",
          {
            autoAlpha: 0,
            duration: 1,
            ease: "sine.out",
          },
          "-=0.2"
        );
      x.animateLogo = false;
    }
  },
};
</script>
<style lang="scss">
.logo {
  height: 80px;
}
.cls-1 {
  fill: #191717;
}
.mobile-menu-icon {
  fill: #fff;
  transition: 0.3s;
  // cursor: pointer;
}
.mobile-menu-icon svg {
  height: 40px;
}
.mobile-menu-icon:hover {
  fill: #7ab2dc;
  // background-color:hsla(0,0%,100%,0.2);
}
.nav-link, .none{
  cursor: none;
}
</style>
