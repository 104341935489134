<template>
  <teleport to="#module">
    <Module ref="module" class="module">
      <div el="list-column-between">
        <h3 el>Covid-19 Policy</h3>
        <span class="material-icons" @click="$refs.module.toggle()" el="icon"
          >close</span
        >
      </div>
      <div el="list sm">
        <CovidPolicy />
      </div>
    </Module>
  </teleport>
  <div id="app">
    <!-- <div class="policy" @click="$refs.module.toggle()">COVID-19 POLICY</div> -->
    <Slideshow />
    <div el="layout-hm fixed">
      <header el="header">
        <Nav />
      </header>
      <main el="main" id="main">
        <div el="list xsm" id="main-center">
          <!-- <a href="docs.html" el="btn">Docs</a> -->
          <!-- <p
            el="italic"
            style="--color:#191717;max-width:800px;line-height:1.5rem;font-size:30px;text-align:center;margin-bottom:32px;font-weight:400;"
          >
            Our new site launches soon!
          </p> -->

          <router-link
            to="/booking"
            el="btn-2ry"
            style="--height:45px;min-width:200px; backdrop-filter: blur(10px); background-color: hsl(0deg 0% 100% / 15%);"
            >VIEW SERVICES</router-link
          >
          <!-- <a href="docs.html" el="btn-2ry" style="--height:45px;">DOCS</a> -->
          <h4 el style="font-weight:300;text-align:center;">
            CREATE THE NEW YOU
          </h4>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Nav from "@/components/Nav.vue";
import Module from "@/components/Module.vue";
import CovidPolicy from "@/components/CovidPolicy.vue";
import Slideshow from "@/components/Slideshow.vue";

export default {
  name: "Home",
  components: {
    Nav,
    Module,
    CovidPolicy,
    Slideshow,
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

[el="main"] {
  justify-content: center;
  display: grid !important;
}
#main {
  align-items: center;
}
#main-center {
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.policy {
  padding: 8px 20px;
  position: fixed;
  right: 0;
  background-color: goldenrod;
  color: #fff;
  top: 16px;
  border-radius: 100px 0px 0px 100px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 25%);
  border: 2px solid;
  border-right: 0;
  /* cursor: pointer; */
  z-index: 5;
  text-decoration: none;
}
@media (max-width: 800px) {
  .policy {
    top: unset;
    bottom: 80px;
  }
}
</style>
