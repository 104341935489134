<template>
    <div el="module-center" v-if="show">
        <div el="list md">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Module',
//   props:['show'],
  data(){
      return {
          show:false,
      }
  },
  methods: {
      toggle(){
          this.show = !this.show;
      }
  },
}
</script>